<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Cajas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Gastos Operativos - Costos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Gastos Operativos - Costos
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO COSTO"
          v-if="'Costo Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nuevo Costo'"
          @click="openModalCosto"
        >
        </Button>
      </div>
    </div>
    <div class="col-12 estilos-form mt-2">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>FECHA INICIO:</strong></span>
          <Calendar
            v-model="fecha_inicio"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA FIN:</strong></span>
          <Calendar
            v-model="fecha_fin"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>SERVICIO:</strong></span>
          <Dropdown
            v-model="servicioSeleccionado"
            :options="servicios"
            optionLabel="descripcion"
            optionValue="id"
            placeholder="Seleccione Servicio"
            @change="cargarCostos"
            :filter="true"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span>&nbsp;</span>
          <Button
            label="BUSCAR"
            icon="pi pi-search"
            class="p-button-info"
            @click="cargarCostos"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 card style_card" v-if="costos">
      <div class="p-fluid formgrid grid">
        <table class="table table-bordered table-striped" style="width: 100%">
          <tr>
            <th class="text-center">
              TOTAL GASTOS OPERATIVOS:
              <span class="p-badge p-badge-blue p-mr-2" style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(totalGastosOperativos || 0)
                }}
                Bs.</span
              >
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_costos"
        dataKey="id"
        :value="costos"
        :paginator="true"
        :loading="cargando"
        :rows="50"
        :rowHover="true"
        class="p-datatable-sm"
        stripedRows
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 200]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Servicios"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :totalRecords="totalRecords"
        selectionMode="single click"
        @row-dblclick="editarCosto($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span
            class="flex align-items-center justify-content-center"
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="id" header="CÓD." :class="'text-right'"> </Column>
        <Column field="fecha_registro" header="FECHA REGISTRO"></Column>
        <Column
          field="nombre_servicio"
          header="SERVICIO/DESCRIPCION"
          style="font-weight: bold"
        >
        </Column>
        <Column field="costo" header="MONTO" style="text-align: right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.costo) }} Bs.
          </template>
        </Column>
        <Column field="observacion" header="OBSERVACION"></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado == 1 ? "ACTIVO" : "INACTIVO" }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <CostoCreate
        :show="costoCrearDialog"
        :costo="{ ...costo }"
        @closeModal="cerrarModalCosto"
        @actualizarListado="cargarCostos"
      />
    </div>
  </div>
</template>

<script>
import CostoCreate from "@/module/costos/CostoCreate.vue";
import CostoService from "@/service/CostoService";

import { useAuth } from "@/stores";
export default {
  components: {
    CostoCreate,
  },

  data() {
    return {
      cargando: true,
      costos: null,
      costoCrearDialog: false,
      costo: {},
      totalRecords: 0,
      claseEstado: ["status-instock", "status-instock", " status-outofstock"],
      fecha_inicio: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ),
      fecha_fin: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ),
      servicios: [],
      servicioSeleccionado: 0,
    };
  },
  costoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.costoService = new CostoService();
  },
  mounted() {
    this.cargarCostos();
  },
  computed: {
    totalGastosOperativos() {
      return this.costos.reduce((acc, item) => acc + parseFloat(item.costo), 0);
    },
  },
  methods: {
    acciones(datos) {
      return [
        {
          label: "Editar Costo",
          disabled: "Costo Editar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-pencil",
          command: () => {
            this.editarCosto(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "Costo Eliminar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-trash",
          command: () => {
            this.eliminarCosto(datos.id);
          },
        },
      ];
    },
    eliminarCosto(id) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL COSTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.costoService.eliminarCosto(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarCostos();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    cargarCostos() {
      let data = {
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        servicio_id: this.servicioSeleccionado,
      };
      this.cargando = true;
      this.costoService.getCostosAll(data).then((data) => {
        this.totalRecords = data.costos ? data.costos.length : 0;
        this.costos = data.costos;
        this.servicios = data.servicios;
        //que seleccione el primer servicio
        this.servicios.unshift({ id: 0, descripcion: "Todos" });
        this.cargando = false;
      });
    },
    editarCosto(datos) {
      this.costo = { ...datos };
      this.costo.costo = parseFloat(datos.costo);
      this.costo.estado = {
        label: datos.estado == 1 ? "ACTIVO" : "INACTIVO",
        value: datos.estado,
      };
      this.costoCrearDialog = true;
    },

    openModalCosto() {
      this.costo = {
        estado: { label: "ACTIVO", value: 1 },
        metodo_pagoSelect: 1,
      };
      this.costoCrearDialog = true;
    },

    cerrarModalCosto() {
      this.costoCrearDialog = false;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.estilos-form {
  background-color: #ececec;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
</style>
