import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CostoService {
  async getCostosAll(data) {
    return await fetchWrapper.post(`${ruta}/costos`, data);
  }

  async saveCosto(datos) {
    const newCosto = await fetchWrapper.post(`${ruta}/costo`, datos);
    return newCosto;
  }

  async updatedCosto(costo) {
    const updateCosto = await fetchWrapper.put(
      `${ruta}/costo/` + costo.id,
      costo
    );
    return updateCosto;
  }

  async eliminarCosto(id) {
    return await fetchWrapper.delete(`${ruta}/costo/${id}`);
  }

  async filtrarCostos(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_costos`, datos);
  }

  async generarPDF(datos) {
    return await fetchWrapper.postPdf(`${ruta}/reporte/costo/imprimir`, datos);
  }

  async exportCostosAll(datos) {
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/costos-export`,
      datos
    );
    return exportado;
  }
}
